// initial state
export const state = {
  filterOption: 'all',
  classFilterOption: 'all',
  statusFilterOption: 'all',
 }
 
 // mutations
export const mutations = {
  setFilterOption (state, option) {
    state.filterOption = option
  },
  setClassFilterOption (state, option) {
    state.classFilterOption = option
  },
  setStatusFilterOption (state, option) {
    state.statusFilterOption = option
  }
}
 
export default {
  namespaced: true,
  state,
  mutations
}
 