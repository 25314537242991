import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-search-select/dist/VueSearchSelect.css'

import VueResource from 'vue-resource'
Vue.use(VueResource)

import ECharts from 'vue-echarts'
import { use, registerTheme } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  LineChart,
  BarChart,
  PieChart,
} from 'echarts/charts'
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  MarkPointComponent
} from 'echarts/components'

use([
  LineChart,
  CanvasRenderer,
  BarChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  MarkPointComponent
]);

import chalk from "./assets/echart-themes/chalk.json";
registerTheme("chalk", chalk);
import essos from "./assets/echart-themes/essos.json";
registerTheme("essos", essos);
import halloween from "./assets/echart-themes/halloween.json";
registerTheme("halloween", halloween);
import infographic from "./assets/echart-themes/infographic.json";
registerTheme("infographic", infographic);
import macarons from "./assets/echart-themes/macarons.json";
registerTheme("macarons", macarons);
import oviliaGreen from "./assets/echart-themes/ovilia-green.json";
registerTheme("ovilia-green", oviliaGreen);
import purplePassion from "./assets/echart-themes/purple-passion.json";
registerTheme("purple-passion", purplePassion);
import roma from "./assets/echart-themes/roma.json";
registerTheme("roma", roma);
import shine from "./assets/echart-themes/shine.json";
registerTheme("shine", shine);
import vintage from "./assets/echart-themes/vintage.json";
registerTheme("vintage", vintage);
import walden from "./assets/echart-themes/walden.json";
registerTheme("walden", walden);
import westeros from "./assets/echart-themes/westeros.json";
registerTheme("westeros", westeros);
import wonderland from "./assets/echart-themes/wonderland.json";
registerTheme("wonderland", wonderland);

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
